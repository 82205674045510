.no-underline {
  text-decoration: none !important
}

.bg-black {
  background-color: #000000;
}

.bg-grey {
  background-color: #333333;
}

.justify-space-between {
  justify-content: space-between;
}

.border-bottom-white {
  border-bottom: 1px solid #ffffff;
}


/*TODO: REFACTOR LIST*/

ul.tree, ul.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tree ul {
  margin-left: 16px;
}
ul.tree li {
  margin: 0;
  padding: 2px 1px;
  line-height: 20px;
  font-weight: bold;
  border-left: 1px solid rgb(100,100,100);

}
ul.tree li:last-child {
  border-left:none;
}
ul.tree li:before {
  position:relative;
  top:-0.2em;
  height:1.1em;
  width:5px;
  color:white;
  border-bottom:1px solid rgb(100,100,100);
  content:"";
  display:inline-block;
  left:-2px;
}
ul.tree li:last-child:before {
  border-left:1px solid rgb(100,100,100);
  left: -1px;
}

/*TODO REFACTOR CALCULATOR INPUT*/

.display {
  background-color: #eee;
}

.d-none {
  display: none;
}

@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: .2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: .2;
  }
}

span.loading span.dot {
  /**
   * Use the blink animation, which is defined above
   */
  animation-name: blink;
  /**
   * The animation should take 1.4 seconds
   */
  animation-duration: 1.4s;
  /**
   * It will repeat itself forever
   */
  animation-iteration-count: infinite;
  /**
   * This makes sure that the starting style (opacity: .2)
   * of the animation is applied before the animation starts.
   * Otherwise we would see a short flash or would have
   * to set the default styling of the dots to the same
   * as the animation. Same applies for the ending styles.
   */
  animation-fill-mode: both;
}

span.loading span.dot:nth-child(2) {
  /**
   * Starts the animation of the third dot
   * with a delay of .2s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: .2s;
}

span.loading span.dot:nth-child(3) {
  /**
   * Starts the animation of the third dot
   * with a delay of .4s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: .4s;
}